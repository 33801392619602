import React, { useEffect, useState } from 'react';
import { POST, GET } from '@/utils/fetch';
import { Table, Button, Space, message, Modal, Form, Input } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// 新增和编辑表单
const Action = ({ children, data, onConfirm }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false)

  // 提交表单
  function submitFrom() {
    form.validateFields()
      .then((formData) => {
        POST(`/api/department/${!data?.id? 'add' : 'update'}`, { ...data, ...formData })
        .then(() => {
          message.success(!data?.id ? '添加成功' : '编辑成功')
          onConfirm?.()
          setOpen(false)
        })
      })
  }
  return <>
    <div onClick={() => { setOpen(true) }}> {children} </div>
    <Modal title={!data?.id ? '添加' : '编辑'} open={open} width={800} okText="提交" cancelText="取消"
      onOk={submitFrom} onCancel={() => { setOpen(false) }} >
      <Form form={form} initialValues={ data || {} } labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} >
        <Form.Item name="name" label="科室名称" rules={[{ required: true, message: '名称必填!' }]} ><Input /></Form.Item>
        <Form.Item name="sort" label="排序">
          <Input placeholder='数字越大排序越前，默认0'  />
        </Form.Item>
      </Form>
    </Modal>
  </>
};


const List = () => {
  const [list, setList] = useState([])

  useEffect(() => {
    getList()
  }, [])

  // 获取列表
  function getList() {
    GET('/api/department/list').then(setList)
  }

  // 删除
  function deleteDepartment(id) {
    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        POST('/api/department/delete', { id })
          .then(() => {
            message.success('删除成功')
            getList()
          })
      },
    })
  }

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id', width: '100px', },
    { title: '科室名称', dataIndex: 'name', key: 'name', width: '200px' },
    {
      title: '操作', dataIndex: '', key: 'x',
      render: (text, record) =>
        <Space >
          { !record.pid && 
            <Action onConfirm={getList} data={{ pid: record.id }}>
              <Button type="primary" size='mini' icon={<>+</>} /> 
            </Action>
          }
          <Action onConfirm={getList} data={record}>
            <Button type="primary" size='mini' icon={<EditOutlined />} />
          </Action>
          <Button type="primary" danger size='mini' icon={<DeleteOutlined />} onClick={() => deleteDepartment(record.id)}></Button>
        </Space >
    }
  ]

  return <>
    <Action onConfirm={getList}>
      <Button type="primary" size='mini' >添加科室</Button>
    </Action>
    <Table rowKey='id' columns={columns} dataSource={list} />
  </>
}

const Variety = () => {
  return (
    <section>
      <h2>科室管理</h2>
      <List />
    </section>
  );
};
export default Variety;