import { Table, Button, Space, DatePicker, Modal, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { POST } from '../../utils/fetch';
import { formatTime } from '@/utils/utils';
import { useNavigate } from 'react-router-dom'
const { RangePicker } = DatePicker;


// 条件筛选表单
const SearchForm = ({ onConfirm }) => {
  const [form] = Form.useForm();

  function submitFrom(type) {
    form.validateFields()
      .then((formData) => {
        if(formData.time) {
          formData.start_time = new Date(`${formData.time[0].format('YYYY-MM-DD')} 00:00:00`).getTime() / 1000
          formData.end_time = new Date(`${formData.time[1].format('YYYY-MM-DD')} 24:00:00`).getTime() / 1000
          delete formData.time
        }
        if( type == 'download' ) {
          formData.download = true
        }
        onConfirm?.(formData)
      })
  }

  return <>
    <Form layout='inline' form={form} style={{ marginBottom: 10 }} >
      <Space wrap  >
        <Form.Item label="订单ID" name='order_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="用户ID" name='user_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="医生ID" name='doctor_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="渠道ID" name='channel_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="下单日期" name='time' >
          <RangePicker format={'YYYY-MM-DD'} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={submitFrom}>搜索</Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => {submitFrom('download')}}>下载表格</Button>
        </Form.Item>
      </Space>
    </Form>
  </>
}
const Order = () => {
  const navigate = useNavigate()
  const [list, setList] = useState()
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    getList()
  }, [])

  const getList = (data = {}) => {
    POST('/admin/order/list', data)
      .then(res => {
        console.log('res', res);
        if(!data.download) {
          setList(res)
          setLoding(false)
          return
        }
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', '订单列表.xls')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
  }

  const columns = [
    { title: '下单日期', dataIndex: 'created_at', width: '150px', render: (v) => formatTime(v, 'Y-M-D') },
    { title: '费用', dataIndex: 'order_price', width: '100px' },
    { title: '支付状态', dataIndex: 'order_status', width: '100px', 
      render: (v) => v == 1 ? '已支付' : '未支付' 
    },
    { title: '科室', dataIndex: 'department_name',  width: '100px' },
    { title: '医生', dataIndex: 'doctor_name', width: '100px' },
    { title: '渠道', dataIndex: 'channel_name', width: '100px',
      render: (v) => v || '无'
     },
    { title: '订单ID', dataIndex: 'order_id', width: '150px', }, 
    { title: '用户ID', dataIndex: 'user_id', width: '100px', render: (v) => (<>
      <a onClick={() => navigate(`/user?user_id=${v}`)}>{v}</a>
    </>) },
  ]

  return (
    <div>
      <h2>订单管理</h2>
      <SearchForm onConfirm={getList} />
      <Table rowKey='id' columns={columns} dataSource={list} loading={loding} />
    </div>
  );
};
export default Order;