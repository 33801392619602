// 时间格式化
export const formatTime = (time, formats = 'Y-M-D h:m:s') => {
    function zero (n) {
      return n < 10 ? '0' + n : String(n)
    }
  
    if (!time) {
      time = new Date().getTime()
    }
    // 10位数的时间戳
    if (Number(time) < 9999999999) {
      time = Number(time) * 1000
    }
  
    const date = new Date(time)
    const year = String(date.getFullYear())
    const month = zero(date.getMonth() + 1)
    const day = zero(date.getDate())
    const hour = zero(date.getHours())
    const minute = zero(date.getMinutes())
    const second = zero(date.getSeconds())
    const dateMap = {
      Y: year,
      M: month,
      D: day,
      h: hour,
      m: minute,
      s: second
    }
    return formats.replace(/Y|m|d|H|i|s/ig, (matches) => {
      return dateMap[String(matches)]
    })
  }

export function downloadFile (url, name) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
}

export function deepClone(target) {
  const map = new WeakMap()
  
  function isObject(target) {
      return (typeof target === 'object' && target ) || typeof target === 'function'
  }

  function clone(data) {
      if (!isObject(data)) {
          return data
      }
      if ([Date, RegExp].includes(data.constructor)) {
          return new data.constructor(data)
      }
      if (typeof data === 'function') {
          return new Function('return ' + data.toString())()
      }
      const exist = map.get(data)
      if (exist) {
          return exist
      }
      if (data instanceof Map) {
          const result = new Map()
          map.set(data, result)
          data.forEach((val, key) => {
              if (isObject(val)) {
                  result.set(key, clone(val))
              } else {
                  result.set(key, val)
              }
          })
          return result
      }
      if (data instanceof Set) {
          const result = new Set()
          map.set(data, result)
          data.forEach(val => {
              if (isObject(val)) {
                  result.add(clone(val))
              } else {
                  result.add(val)
              }
          })
          return result
      }
      const keys = Reflect.ownKeys(data)
      const allDesc = Object.getOwnPropertyDescriptors(data)
      const result = Object.create(Object.getPrototypeOf(data), allDesc)
      map.set(data, result)
      keys.forEach(key => {
          const val = data[key]
          if (isObject(val)) {
              result[key] = clone(val)
          } else {
              result[key] = val
          }
      })
      return result
  }

  return clone(target)
}
