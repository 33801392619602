import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { HomeOutlined, AppstoreOutlined, GiftOutlined, UserOutlined } from '@ant-design/icons'
import { ConfigProvider, Layout, Menu } from 'antd'
import zhCN from 'antd/locale/zh_CN';

const {  Content, Footer, Sider } = Layout
const items = [
  { label: '账号', key: 'role', icon: <UserOutlined /> },
  { label: '科室', key: 'department', icon: <AppstoreOutlined /> },
  { label: '医生', key: 'doctor', icon: <GiftOutlined /> },
  { label: '订单', key: 'order', icon: <HomeOutlined /> },
  { label: '用户', key: 'user', icon: <HomeOutlined /> },
  { label: '渠道', key: 'channel', icon: <HomeOutlined /> }
]

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['department'])

  useEffect(() => {
    const key = location.pathname.split('/')[1]
    setSelectedKeys([key])
  }, [location]);

  return (
    <ConfigProvider locale={zhCN}>
      <Layout style={{ height: '100vh' }} >
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <div style={{ height: 32 }} />
          <Menu theme="dark" mode="inline" items={items} selectedKeys={selectedKeys} onClick={(e) => navigate(e.key)} />
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: '16px 0 0 16px', height: '100vh', overflow: 'auto' }} >
            <div style={{ padding: 24, minHeight: 360, background: '#fff' }} >
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', padding: 10 }} > ©2024 </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
export default Home;