import { Table, Button, Space, message, Modal, Form, Input, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { POST } from '../../utils/fetch';
import { formatTime } from '../../utils/utils';
const { RangePicker } = DatePicker;


// 条件筛选表单
const SearchForm = ({ onConfirm }) => {
  const [form] = Form.useForm();

  function submitFrom() {
    form.validateFields()
      .then((formData) => {
        if(formData.time) {
          formData.start_time = new Date(`${formData.time[0].format('YYYY-MM-DD')} 00:00:00`).getTime() / 1000
          formData.end_time = new Date(`${formData.time[1].format('YYYY-MM-DD')} 24:00:00`).getTime() / 1000
          delete formData.time
        }
        onConfirm?.(formData)
      })
  }

  return <>
    <Form layout='inline' form={form} style={{ marginBottom: 10 }} >
      <Space wrap  >
        <Form.Item label="用户ID" name='user_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="渠道ID" name='channel_id' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="症状" name='symptom' >
          <Input allowClear placeholder='请输入' />
        </Form.Item>
        <Form.Item label="注册日期" name='time' >
            <RangePicker format={'YYYY-MM-DD'} />
          </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={submitFrom}>搜索</Button>
        </Form.Item>
      </Space>
    </Form>
  </>
}

const User = () => {
  const [list, setList] = useState()
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    new URLSearchParams(window.location.search);
    const user_id = new URLSearchParams(window.location.search).get('user_id');
    user_id ? getList({ user_id }) : getList()
  }, [])

  const getList = async (data = {}) => {
    const res = await POST('/api/user/list', data)
    setList(res)
    setLoding(false)
  }

  // 删除
  function deleteUser(params) {
    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        POST('/api/user/delete', { user_id: params.user_id })
          .then(() => {
            message.success('删除成功', params.id)
            getList()
          })
      },
    })
  }
  

  const columns = [
    { title: '用户ID', dataIndex: 'user_id', width: '100px' },
    { title: '姓名', dataIndex: 'user_name', width: '150px' },
    { title: '性别', dataIndex: 'sex', width: '150px',
      render: (text) => text == 1 ? '男' : text == 2 ? '女' : '保密' 
    },
    { title: '年龄', dataIndex: 'age', width: '100px' },
    { title: '手机号', dataIndex: 'phone_number', width: '200px' },
    
    { title: '地址', dataIndex: 'address', width: '300px' },
    { title: '症状', dataIndex: 'symptom', width: '300px' },

    { title: '出生日期', dataIndex: 'bithday', width: '200px' },
    { title: '注册时间', dataIndex: 'created_at', width: '200px',
      render: (text) => formatTime(text, 'Y-M-D')
    },
    { title: '渠道', dataIndex: 'channel_name', width: '100px',
      render: v => v || '无'
    },
    // { title: '备注', dataIndex: 'remark', width: '300px' },
    {
      title: '操作',
      render: (text, record) =>
        <Space>
          {/* <Button type="primary" size='mini'> 详情 </Button> */}
          <Button type="primary" danger size='mini' icon={<DeleteOutlined />} 
            onClick={() =>  deleteUser(record)}></Button>
        </Space>
    }
  ]

  return (
    <div>
      <h2>用户管理</h2>
      <SearchForm onConfirm={getList} />
      <Table rowKey='id' columns={columns} dataSource={list} loading={loding} />
    </div>
  );
};
export default User;