import React from 'react';
import ReactDOM from 'react-dom/client';
import {  createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';

import Login from './view/login/login';
import Layout from './view/layout/layout';
import Home from './view/home/home'
import Role from './view/role/role' // 账号
import Department from './view/department/department' // 科室
import Doctor from './view/doctor/doctor' // 医生
import Order from './view/order/order' // 订单
import User from './view/user/user' // 用户
import Channel from './view/channel/channel' // 渠道

const token = localStorage.getItem('token')
if(!token && window.location.pathname !== '/login') {
  window.location.href = '/login'
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/role", element: <Role /> },
      { path: "/department", element: <Department /> },
      { path: "/doctor", element: <Doctor /> },
      { path: "/order", element: <Order /> },
      { path: "/user", element: <User /> },
      { path: "/channel", element: <Channel /> },
    ]
  },
  {
    path: "/login",
    element: <Login />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

