import React, { useEffect, useState } from 'react';
import { Table, Button, Space, message, Modal, Form, Input, Select } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { GET, POST } from '../../utils/fetch';
import { downloadFile } from '@/utils/utils';

// 新增和编辑表单
const Action = ({ children, data, onConfirm }) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState(false)
  // 提交表单
  function submitFrom() {
    form.validateFields()
      .then(formData => {
        data?.id && (formData.id = data.id)
        console.log(formData);
        POST(`/api/channel/${!data?.id ? 'add' : 'update'}`, formData)
        .then(() => {
          message.success(data?.id ? '编辑成功' : '添加成功')
          setShow(false)
          onConfirm?.()
        })
      }).catch(console.error)
  }
  return <>
    <span onClick={() => { setShow(true) }}> {children} </span>
    <Modal title={!data ? '添加' : '编辑'} open={show} width={800} okText="提交" cancelText="取消"
      onOk={submitFrom} onCancel={() => { setShow(false) }} >
      <Form form={form} initialValues={ data || {} } labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} >
        <Form.Item name="name" label="渠道名称" rules={[{ required: true, message: '名称必填!' }]} >
          <Input />
        </Form.Item>
        <Form.Item name="remark" label="备注">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

// 条件筛选表单
const SearchForm = ({ onConfirm }) => {
  const [form] = Form.useForm();

  function submitFrom() {
    form.validateFields()
      .then((formData) => onConfirm?.(formData))
  }

  return <>
    <Form layout='inline' form={form} style={{ marginBottom: 10 }} >
      <Form.Item label="渠道名称" name='name' >
        <Input allowClear placeholder='请输入' />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={submitFrom}>搜索</Button>
      </Form.Item>
    </Form>
  </>
}

// 渠道管理页面
const Channel = () => {
  const [list, setList] = useState()
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    getList({})
  }, [])

  function getList(form) {
    POST('/api/channel/list', form)
      .then(res => {
        setList(res)
        setLoding(false)
      })
  }

  // 二维码
  function getQrCode ({id, name}) {
    GET('/api/channel/qrcode', { id })
      .then(res => {
        downloadFile(res, name + '渠道二维码.png')
      })
  }

  // 删除
  function deleteChannel(params) {
    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        POST('/api/channel/delete', { id: params.id })
          .then(() => {
            message.success('删除成功', params.id)
            getList()
          })
      },
    })
  }

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id',  width: '50px', }, 
    { title: '渠道名称', dataIndex: 'name', key: 'name',  width: '200px' },
    { title: '备注', dataIndex: 'remark', key: 'remark',  width: '300px' },
    {
      title: '操作', dataIndex: '', key: 'x',
      render: (text, record) =>
        <Space >
          <Button type="primary" size='mini' icon={<QrcodeOutlined />} onClick={() => getQrCode(record)} />
          <Action onConfirm={getList} data={record} >
            <Button type="primary" size='mini' icon={<EditOutlined />} />
          </Action>
          <Button type="primary" danger size='mini' icon={<DeleteOutlined />} onClick={() => deleteChannel(record)}></Button>
        </Space >
    }
  ]

  return (
    <div>
      <h2>渠道管理</h2>
      
      <SearchForm onConfirm={getList} />
      
      <Action onConfirm={getList}>
        <Button type="primary" size='mini' >添加渠道</Button>
      </Action>

      <Table rowKey='id' columns={columns} dataSource={list} loading={loding} />
    </div>
  );
};
export default Channel;