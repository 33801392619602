import { Table, Form, Input, InputNumber, Switch, Modal, message, Button, Select, Space } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { GET, POST } from '../../utils/fetch';
import SingleUploadFile from '@/components/UploadFile/SingleUploadFile';

// 获取科室列表
let departmentList = []
async function getDepartmentList() {
  if(departmentList?.[0]) return departmentList;
  const res = await GET('/api/department/list')
  console.log('获取科室列表', res)
  departmentList = []
  res.forEach(item => {
    const group = { label: item.name, options: [] }
    item.children?.forEach(option => {
      group.options.push({ label: option.name, value: option.id })
    })
    departmentList.push(group) 
  });
  return departmentList
}

// 新增和编辑表单
const Action = ({ children, data, onConfirm }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false)
  const [departmentOptions, setDepartmentOptions] = useState([])

  useEffect(() => {
    getDepartmentList().then(setDepartmentOptions)
  }, [])

  // 提交表单
  function submitFrom() {
    form.validateFields()
      .then((formData) => {
        formData.visible = formData.visible ? 1 : 0
        // 用department_id查找对应科室名称
        if(formData.department_id) {
          departmentOptions.find(item => {
            item.options?.find(option => {
              if(option.value === formData.department_id) {
                formData.department = option.label
                return true
              }
            })
          })
        }
        POST(`/api/doctor/${!data?.id? 'add' : 'update'}`, { ...data, ...formData })
        .then(() => {
          message.success(!data?.id ? '添加成功' : '编辑成功')
          onConfirm?.()
          setOpen(false)
        })
      })
  }

  return <>
    <span onClick={() => { setOpen(true) }}> {children} </span>
    <Modal title={!data?.id ? '添加' : '编辑'} open={open} width={800} okText="提交" cancelText="取消"
      onOk={submitFrom} onCancel={() => { setOpen(false) }} >
      <Form form={form} initialValues={ data || {} } labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} >
        <Form.Item name="name" label="姓名" rules={[{ required: true, message: '姓名必填!' }]} >
          <Input />
        </Form.Item>
        <Form.Item name="department_id" label="科室" rules={[{ required: true, message: '科室必选!' }]}  >
          <Select style={{ width: 312 }} options={departmentOptions} />
        </Form.Item>
        <Form.Item label="头像" >
          <SingleUploadFile onPath={(url) => form.setFieldsValue({ avatar: url })} preUrl={data?.avatar} />
          <Form.Item hidden name="avatar"><Input /></Form.Item>
        </Form.Item>
        <Form.Item name="job_title" label="职称" ><Input /></Form.Item>
        <Form.Item name="main_field" label="擅长领域" ><Input /></Form.Item>
        <Form.Item name="describe" label="描述" ><Input /></Form.Item>
        <Form.Item name="visible" valuePropName='checked' label="是否显示" >
          <Switch />
        </Form.Item>
        <Form.Item name="sort" label="排序">
          <InputNumber style={{ width: 220 }} placeholder='数字越大排序越前，默认0' ></InputNumber>
        </Form.Item>
      </Form>
    </Modal>
  </>
};

// 条件筛选表单
const SearchForm = ({ onConfirm }) => {
  const [form] = Form.useForm();
  const [departmentOptions, setDepartmentOptions] = useState([])

  useEffect(() => {
    getDepartmentList().then(setDepartmentOptions)
  }, [])

  function submitFrom() {
    form.validateFields()
      .then((formData) => onConfirm?.(formData) )
  }
  
  return <>
    <Form layout='inline' form={form} style={{ marginBottom: 10 }} >
      <Form.Item label="姓名" name='name' >
        <Input allowClear placeholder='请输入' />
      </Form.Item>
      <Form.Item label="科室" name="department_id" >
        <Select style={{ width: 212 }} options={departmentOptions} allowClear />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={submitFrom}>搜索</Button>
      </Form.Item>
    </Form>
  </>
}

const Doctor = () => {
  const [list, setList] = useState()
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    getList()
  }, [])

  async function getList(form = {}) {
    setLoding(true)
    const data = await POST('/api/doctor/list', form)
    setList(data)
    setLoding(false)
  }

  // 删除
  function deleteDoctor(id) {
    Modal.confirm({
      title: '确认删除吗？',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await POST('/api/doctor/delete', { id })
        message.success('删除成功')
        getList()
      }
    })
  }

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id',  width: '50px' }, 
    {
      title: '头像', dataIndex: 'avatar', width: '120px',
      render: (text, record) => record.avatar ? <img src={record.avatar} alt='图标' style={{ maxWidth: '100px', maxHeight: '100px' }} /> : ''
    },
    { title: '姓名', dataIndex: 'name', key: 'name',  width: '100px' },
    { title: '职称', dataIndex: 'job_title', key: 'job_title',  width: '150px' },
    { title: '科室', dataIndex: 'department', key: 'department',  width: '100px' },
    { title: '擅长领域', dataIndex: 'main_field', key: 'main_field'  },
    { title: '描述', dataIndex: 'describe', key: 'describe' },
    { title: '是否显示', dataIndex: 'visible',  width: '100px',
      render: (text, record) => record.visible ? '是' : '否'
    },
    { title: '操作', 
      render: (text, record) =>
        <Space >
          <Action onConfirm={getList} data={record}>
            <Button type="primary" size='mini' icon={<EditOutlined />} />
          </Action>
          <Button type="primary" danger size='mini' icon={<DeleteOutlined />} onClick={() => deleteDoctor(record.id)}></Button>
        </Space >
    }
  ]

  return (
    <div>
      <h2>医生管理</h2>
      
      <SearchForm onConfirm={getList} />

      <Action onConfirm={getList}>
        <Button type="primary" size='mini' >添加医生</Button>
      </Action>
      <Table rowKey='id' columns={columns} dataSource={list} loading={loding} pagination={false} />
    </div>
  );
};
export default Doctor;