import React from 'react';
import { Button, Form, Input } from 'antd';
import { POST } from '../../utils/fetch';
import { useNavigate } from 'react-router-dom';
const Login = () => {
  const navigate = useNavigate()
  const onFinish = async (values) => {
    const res = await POST('/api/account/login', values)
    localStorage.setItem('token', res.token)
    navigate('/')
  };
  return (
    <Form name="login" autoComplete="off" onFinish={onFinish} style={{ marginTop: '30vh' }}
      labelCol={{ span: 8 }} wrapperCol={{ span: 5 }} initialValues={{ remember: true }} >
      <Form.Item label="账号" name="account" rules={[{ required: true, message: '请输入账号!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入密码!' }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 10, span: 16 }} >
        <Button type="primary" htmlType="submit"> 登录 </Button>
      </Form.Item>
    </Form>
  );
};
export default Login;