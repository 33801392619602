import { message } from 'antd';

let authorization = localStorage.getItem('token')

async function _fetch (method, url, body, headers) {
  if(!authorization) {
    authorization = localStorage.getItem('token')
  }
  const init = {
    method,
    headers: {
      authorization,
      ...headers
    },
    ...body
  }
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + url, init)
      .then(response => { 
        // 文件数据流直接返回
        const type = response.headers.get('Content-Type') == 'application/octet-stream' ? 'blob' : 'json'
        if (type === 'blob') {
          return { code: 200, data: response.blob() }
        }
        return response.json()
      })
      .then(res => {
        if(res.code == 200) return resolve(res.data)
        if(res.code == 403) {
          window.location.href = '/login'
          return
        }
        if (res.code !== 200) {
          message.error(res.msg)
          console.error('错误：', res)
          return
        }
      })
      .catch(err => {
        message.error('服务器错误：' + err)
        console.error('服务器错误：', err)
        return reject(false)
      })
  })
}

// get参数处理
function _paramsToQueryStr (url, object) {
  if (!object) return url
  let query = url
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      query += `${query.indexOf('?') === -1 ? '?' : '&' }${key}=${element}`
    }
  }
  return query
}

export function POST ( url, params, headers = {} ) {
  const body = { body: JSON.stringify(params) }
  headers = {
    'content-type': 'application/json',
    ...headers
  }
  return _fetch('post', url, body, headers)
}

export function GET ( url, params, headers = {} ) {
  const urlAndParams = _paramsToQueryStr(url, params)
  headers = {
    'content-type': 'application/json',
    ...headers
  }
  return _fetch('get', urlAndParams, {}, headers)
}

export function UPLOAD ( url, params, headers = {} ) {
  const body = { body: params }
  return _fetch('post', url, body, headers)
}