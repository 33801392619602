import React, { useState, useEffect } from 'react';
import UploadFile from './Upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const MultiUploadFile = (props) => {
	const { preUrl, onPath } = props
	const [imageUrl, setImageUrl] = useState();
	const [loading, ] = useState(false);

	useEffect(() => {
		preUrl ? setImageUrl(preUrl) : setImageUrl('')
	}, [])

	const onUploaded = (info) => {
		setImageUrl(info.preUrl)
		onPath(info.path)
	}

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }} >点击上传</div>
		</div>
	)
	return (
		<>
			<UploadFile preUrl={preUrl} onUploaded={onUploaded}>
				{imageUrl
					? (<img src={imageUrl} alt="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />)
					: (uploadButton)}
			</UploadFile>
		</>
	)
}
export default MultiUploadFile;
