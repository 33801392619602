import { Upload } from 'antd';

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

const UploadFile = (props) => {
	const { children, onUploaded } = props
	const authorization = localStorage.getItem('token')
	const handleChange = (info) => {
		if (info.file.status === 'done') {
			const { response } = info.file
			getBase64(info.file.originFileObj, (base64) => {
				if (response.code === 200) {
					onUploaded({
						preUrl: base64,
						path: response?.data?.url
					})
				}
			})
		}
	}
	return (
		<Upload name="image" listType="picture-card" accept=".jpg,.jpeg,.png" showUploadList={false}
			action={`${process.env.REACT_APP_BASE_URL}/api/common/upload`} headers={{ authorization }} onChange={handleChange} >
			{ children }
		</Upload>
	)
}
export default UploadFile;