import { Table, message, Modal, Form, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { GET, POST } from '../../utils/fetch';

// 新增和编辑表单
const Action = ({ children, data }) => {
  const [form] = Form.useForm();
  const [show, setShow] = useState(false)
  // 提交表单
  function submitFrom() {
    form.validateFields()
      .then(formData => {
        POST(`/api/account/update`, { id: data.id, ...formData })
        .then(() => {
          message.success('修改成功')
          setShow(false)
        })
      }).catch(console.error)
  }
  return <>
    <div onClick={() => { setShow(true) }}> {children} </div>
    <Modal title="修改密码" open={show} width={800} okText="提交" cancelText="取消"
      onOk={submitFrom} onCancel={() => { setShow(false) }} >
      <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} >
        <Form.Item name="password" label="新密码" rules={[{ required: true, message: '必填!' }]} >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  </>
}

const Role = () => {
  const [list, setList] = useState()
  const [loding, setLoding] = useState(true)

  useEffect(() => {
    setLoding(false)
    GET('/api/account/list')
      .then(data => {
        setList(data)
        setLoding(false)
      })
  }, [])

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id',  width: '50px', }, 
    { title: '名称', dataIndex: 'name', key: 'name',  width: '200px' },
    { title: '账号', dataIndex: 'account', key: 'account',  width: '200px' },
    { title: '操作', dataIndex: '', key: 'x',
      render: (text, record) =>
        <Action data={record}>
          <Button type="primary" size='mini' icon={<EditOutlined />} />
        </Action>
    }
  ]

  return (
    <div>
      <h2>账号管理</h2>
      <Table rowKey='id' columns={columns} dataSource={list} loading={loding} />
    </div>
  );
};
export default Role;